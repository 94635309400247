import { useState } from 'react'
import styles from './SitePreview.module.css'

function SitePreview(props) {
  const handleOpenModal = () => {
    props.setIsOpen(true)
  }

  const handleCloseModal = () => {
    props.setIsOpen(false)
  }
  const link = `https://${props.url}`

  return (
    <div className={styles.sitePreviewContainer}>
      <img
        className={styles.sitePreviewImg}
        src={`https://api.apiflash.com/v1/urltoimage?access_key=0e78203a7da342f3b222e6fca3822679&wait_until=page_loaded&url=HTTPS://${encodeURIComponent(
          props.url
        )}`}
        alt="Site Preview"
      />
      <h4>{props.name}</h4>
      <p>{props.about}</p>
      <p>{props.techs}</p>
      {props.git ? (
        <button
          className={styles.gitButton}
          onClick={() => window.open(props.git)}
        >
          See code on GitHub
        </button>
      ) : null}
      <a
        href={link}
        className={styles.sitePreviewLink}
        target="_blank"
        rel="noreferrer"
      >
        {props.url}
      </a>
      {props.isOpen && (
        <div className={styles.modalOverlay} onClick={handleCloseModal}>
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <button className={styles.closeButton} onClick={handleCloseModal}>
              &times;
            </button>
            <iframe
              src={link}
              title="Site Preview"
              className={styles.iframe}
            ></iframe>
          </div>
        </div>
      )}
    </div>
  )
}

export default SitePreview
