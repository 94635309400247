import styles from './Popup.module.css'
import data from '../data/mySkills.json'

function Popup(props) {
  const closePopup = () => {
    props.setShowPopup(false)
    document.body.style.overflow = ''
  }
  const disableScroll = () => {
    document.body.style.overflow = 'hidden'
  }

  const enableScroll = () => {
    document.body.style.overflow = ''
  }

  // Поиск данных для соответствующей технологии
  const skillData = data.find((skill) => skill.title === props.tech)

  return (
    <div
      className={styles.popupWrapper}
      onClick={closePopup}
      onMouseOver={disableScroll}
      onMouseOut={enableScroll}
      key={props.tech}
    >
      <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
        <h1>{props.tech}</h1>
        <button className={styles.closePopup} onClick={closePopup}>
          ⓧ
        </button>
        {skillData ? (
          <div>
            {skillData.knowledge.map((knowledgeItem) => (
              <ul key={knowledgeItem.id}>
                <li>{knowledgeItem}</li>
              </ul>
            ))}
          </div>
        ) : (
          <p>Keine Informationen verfügbar.</p> // Сообщение, если данных нет
        )}
      </div>
    </div>
  )
}

export default Popup
