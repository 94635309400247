import SitePreview from './SitePreview'
import styles from './Projects.module.css'

function Projects({ isOpen, setIsOpen }) {
  return (
    <div className={styles.projectsSection} id="projects">
      <h1>My Projects</h1>
      <div className={styles.projectsContainer}>
        <SitePreview
          url="www.quantoloop.com"
          name="Quantoloop"
          about="Meine erste Website, die ich im Rahmen meines Praktikums bei Quantaloop erstellt habe."
          techs="HTML/CSS/Vanilla JS"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <SitePreview
          url="www.diamate.de"
          name="Diamate"
          about="Meine zweite Website, die ich im Rahmen meines Praktikums bei Quantaloop erstellt habe."
          techs="HTML/CSS/Vanilla JS"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <SitePreview
          url="my-project-react-taupe.vercel.app"
          name="Todo/Music/Weather App"
          about="Mein erstes React-Projekt, das ich im Rahmen meiner Weiterbildung erstellt habe."
          techs="CSS/JavaScript/React"
          git="github.com/kalatyn/my_web_app_NEXT_JS-main"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <SitePreview
          url="my-project-tetris.vercel.app"
          name="My Tetris Game"
          about="Mein erstes JS-Projekt, das ich im Rahmen meiner Weiterbildung erstellt habe."
          techs="CSS/JavaScript/React"
          git="github.com/kalatyn/my-project-Tetris"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
      <span className={styles.tech_disc}>CSS/React</span>
    </div>
  )
}
export default Projects
