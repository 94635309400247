import './App.css'
import Skills from './Components/Skills'
import { useState } from 'react'
import Popup from './Components/Popup'
import Projects from './Components/Projects'
import Downloads from './Components/Downloads'

function App() {
  const [showPopup, setShowPopup] = useState(false)
  const [choosedTech, setChoosedTech] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="App">
      <Skills
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        tech={choosedTech}
        setTech={setChoosedTech}
      />
      <Projects isOpen={isOpen} setIsOpen={setIsOpen} />
      {showPopup && <Popup tech={choosedTech} setShowPopup={setShowPopup} />}

      <Downloads />
    </div>
  )
}

export default App
