import styles from './Skill.module.css'

function Skill({ title, logo, setTech, setShowPopup, experience }) {
  const handleClick = () => {
    setTech(title)
    setShowPopup(true)
  }

  return (
    <div className={styles.skill} onClick={() => handleClick()}>
      <p className={styles.experience}>{experience ? '🌳' : '🪴'}</p>
      <h3>{title}</h3>
      <img src={logo} alt={title} />
    </div>
  )
}
export default Skill
