import styles from './Downloads.module.css'
function Downloads() {
  const documents = [
    { name: 'Lebenslauf', file: 'Kalakutok, Wadim Lebenslauf.pdf' },
    {
      name: 'Zertifikat',
      file: 'Zertifikat_Kalakutok_W.pdf',
    },
    {
      name: 'Arbeitszeugnis',
      file: 'Kalakutok, Wadim Arbeitszeugnis.pdf',
    },
  ]

  return (
    <div className={styles.downloadsSection} id="downloads">
      <h1>Downloads</h1>
      <div className={styles.documentsContainer}>
        {documents.map((document) => (
          <div key={document.name}>
            <a
              className={styles.documentsName}
              key={document.name}
              href={`./documents/${document.file}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {document.name}
            </a>
          </div>
        ))}
      </div>
      <span className={styles.tech_disc}>CSS/React</span>
    </div>
  )
}
export default Downloads
