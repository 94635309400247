import Skill from './Skill'
import data from '../data/mySkills.json'
import styles from './Skills.module.css'

function Skills({ showPopup, setShowPopup, tech, setTech }) {
  return (
    <div className={styles.skillsSection} id="skills">
      <h1>My Skills</h1>
      <p>
        <span style={{ fontSize: '30px' }}>🌳</span> - Ich habe bereits
        praktische Erfahrung mit diesen Technologien.
      </p>
      <p>
        <span style={{ fontSize: '30px' }}>🪴</span> - Ich habe allgemeines
        Wissen, aber keine praktische Erfahrung mit diesen Technologien.
      </p>
      <div className={styles.skillsContainer}>
        {data.map((skill) => (
          <Skill
            key={skill.id}
            title={skill.title}
            logo={skill.logo}
            experience={skill.experience}
            setTech={setTech}
            showPopup={showPopup}
            setShowPopup={setShowPopup}
          />
        ))}
      </div>
      <span className={styles.tech_disc}>CSS/JavaScript/React</span>
    </div>
  )
}
export default Skills
